<template>
  <div
    class="class5-oxidizing-materials px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Tests for Class 5 (Oxidizing Materials)"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Class 5.1: Is the substance or article an Oxidizing Solid or Liquid?"
        />

        <Paragraph
          ><h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Tests for Oxidizing Solids &amp; Liquids
          </h4>
          <p>
            The test for oxidizing solids is designed to measure the potential
            for a solid substance to increase the burning rate or burning
            intensity of a combustible substance when the two are thoroughly
            mixed. Tests are conducted on the substance to be evaluated mixed
            with dry fibrous cellulose in mixing ratios of 1:1 and 4:1, by mass,
            of sample to cellulose. The burning characteristics of the mixtures
            are compared with the standard 3:7 mixture, by mass, of potassium
            bromate to cellulose. If the burning time is equal to or less than
            this standard mixture, the burning times are compared with those
            from the Packing Group I or II reference standards, 3:2 and 2:3
            ratios, by mass, of potassium bromate to cellulose respectively.
          </p>
          <p>
            The test for oxidizing liquids measures the potential for a liquid
            substance to 1) increase the burning rate of a combustible substance
            when the two are thoroughly mixed or 2) to form a mixture that
            spontaneously ignites. The liquid is mixed with fibrous cellulose in
            a 1:1 ratio, by mass. The mixture is then heated in a pressure
            vessel and the rate of pressure rise is measured with a pressure
            transducer. The reaction rate is defined as the time taken for the
            pressure to rise from 100 to 300 psi. The mean reaction rate of five
            trials of the mixture is compared to the mean reaction rate of five
            trials of reference oxidizing liquids. Reference materials include a
            nitric acid (65% solution) mixture, a sodium chlorate (40% aqueous)
            mixture, and a perchloric acid (50% solution) mixture. The liquid is
            not considered an oxidizer if the rate of pressurization of the
            vessel is slower than the nitric acid (65% solution) mixture. A
            packing group for each material is assigned based on the reaction
            time of the mixture compared to the reference mixtures. If the
            sample material spontaneously ignites or exhibits a mean pressure
            rise time less than that of the perchloric acid mixture, the sample
            material is assigned to Packing Group I. If the sample material
            exhibits a mean pressure rise time less than or equal to that of the
            sodium chlorate mixture and does not fall under Packing Group I, the
            sample material is assigned to Packing Group II. If the sample
            material exhibits a mean pressure rise time less than or equal to
            that of the nitric acid mixture and does not fall under Packing
            Groups I or II, the sample material is assigned to Packing Group
            III.
          </p>
          <p>Reference: Transport of Dangerous Goods – Tests and Criteria</p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Class 5 Oxidizing Material Tests",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "To classify a substance or article as Class 5 (Oxidizing material) it must be determined whether mixing it with a known combustible substance increases the burning rate or forms a mixture that spontaneously ignites."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.class5-oxidizing-materials {
  h4 {
    font-size: 13px;
    color: black;
    margin-bottom: 1.3em;
    margin-top: 1.3em;
  }
}
</style>
